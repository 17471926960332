import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { CategoryWithMap } from "./categoryWithMap"
import { useStaticQuery, graphql } from 'gatsby';

/**
 * Styles
 */
const Wrapper = styled.div`
  width: min(90%, 960px);
  margin: auto;
  padding-top: 56px;
  display: flex;
  flex-flow: column;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    padding-top: 24px;
  }
`
const Content = styled.div`
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  line-height: 32px;
  color: #222222;
  display: inline-flex;
  padding: 40px 0 40px 24px;
  border-bottom: 1px solid #CBCBCB;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    line-height: 26px;
    padding: 18px 0 18px 16px;
  }
`
const Item = styled.span`
  font-weight: 600;
  width: 176px;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 80px;
  }
`
const Text = styled.span`
  font-weight: 400;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: calc(100% - 80px);
  }
`
const SpBr = styled.br`
  display: none;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: inline;
  }
`

/**
 * Props
 */
type Props = {
  link: string;
}

/**
 * Component
 */
export const Category: React.VFC<Props> = (props) => {
  const pin = useStaticQuery(graphql`
    query{
      map_pin: file(relativePath: { eq: "images/map_pin@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 30, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return(
    <Wrapper>
      <Content>
        <Item>会社名</Item>
        <Text>ネクストライブ株式会社<SpBr/>(英名：Nextribe Inc.)</Text>
      </Content>
      <CategoryWithMap image={pin} link={props.link} />
      <Content>
        <Item>設立</Item>
        <Text>2015年5月1日</Text>
      </Content>
      <Content>
        <Item>資本金</Item>
        <Text>1,000万円</Text>
      </Content>
      <Content>
        <Item>役員</Item>
        <Text>代表取締役　狗巻 雄介<br/>取締役　日巻 貴之<br/>取締役　木村 元<br/>執行役員　武輪 遼</Text>
      </Content>
      <Content>
        <Item>社員数</Item>
        <Text>30名</Text>
      </Content>
      <Content>
        <Item>事業内容</Item>
        <Text>
          SaaS型クラウドサービスの開発・運営<br />
          ソフトウェアの開発・運用・保守<br />
          Webサイトの制作・運用・保守<br />
          IT人材育成サービスの開発・運営<br />
          システム開発支援サービスの提供
        </Text>
      </Content>
      <Content>
        <Item>許認可</Item>
        <Text>
          電気通信事業 E-02-04639<br />
          労働者派遣事業 派27-304889<br/>
          有料職業紹介事業 27-ユ-303675<br/>
          ISMS認証（ISO27001）IS 765811<SpBr/>
        </Text>
      </Content>
      <Content>
        <Item>適格請求書発行<br />事業者登録番号</Item>
        <Text>T8120001190639</Text>
      </Content>
    </Wrapper>
  )
}