import React from "react"
import { graphql } from "gatsby";
import { Profile } from '../components/profile'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `COMPANY PROFILE`, path: `company` },
]

export default function Home({data}) {
  const { ogpImage } = data;

  return (
    <Profile breadcrumbsData={breadcrumbsData}
      pinImg={""}
      address={""}
      ogpImage={ogpImage}
    />
  )
}

export const query = graphql`
  query {
    ogpImage: file(relativePath: { eq: "images/top_ogp.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
