import React from "react"
import styled from "styled-components"
import GoogleMap from "google-map-react"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../../AppConst"

/**
 * Elements & Styles
 */
const Node = styled.div`
  width: min(90%, 960px);
  height: 400px;
  display: inline-flex;
  border-radius: 20px;
  background-color: #CBCBCB;
  & > div > div{
    border-radius: 20px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    & > div > div{
      border-radius: 10px;
    }
  }
`
const Pin = styled.div<{
  lat: number,
  lng: number,
}>`
`

/**
 * Props
 */
type Props = {
  pinImg: any;
}

/**
 * Component
 */
export const Map: React.VFC<Props> = (props) => {
  return (
    <Node>
      <GoogleMap
        bootstrapURLKeys={{
          key: `${process.env.GATSBY_GOOGLE_MAP_API_KEY}`
        }}
        defaultCenter={{
          lat: 34.695813,
          lng: 135.489732
        }}
        defaultZoom={15}
        >
        <Pin
        lat={34.695813}
        lng={135.489732}
        style={{width:45, height:65}}
        >
        <Img
          fixed={props.pinImg}
          alt=""
          style={{top:'-100%', left:'-50%'}}
        />
        </Pin>
      </GoogleMap>
    </Node>
  )
}