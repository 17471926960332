import React from "react"
import styled from "styled-components";
import Layout from "../../template/Layout"
import Head from "../../head";
import { SectionTitle } from '../common/SectionTitle'
import { Category } from "./main/category";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../AppConst";
import Breadcrumbs, {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { GMap } from "./map";
import { graphql, useStaticQuery } from "gatsby";
import { ogpUrl } from "../../utlis/ImageUtil"

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
`

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"];
  pinImg: any;
  address: string;
  ogpImage: any;
}

/**
 * Component
 */
export const Profile: React.VFC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      pin_img: file(relativePath: { eq: "images/pin@2x.png" }) {
        childImageSharp {
          fixed(width: 50, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allSite {
          edges {
            node {
              siteMetadata {
              address
              }
            }
          }
        }
    }
  `)

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="会社概要 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の会社概要ページです。所在地、設立年度、資本金、役員、社員数、事業内容などを掲載しています。"
        img={ogpUrl(process.env.NODE_ENV, props.ogpImage.childImageSharp.fluid)}
      />
      <Node>
        <SectionTitle title='company profile' subTitle='会社概要' color='#222222'/>
        <Category link={data.allSite.edges[0].node.siteMetadata.address} />
        <GMap pinImg={data.pin_img.childImageSharp.fixed} />
      </Node>
    </Layout>
  )
}