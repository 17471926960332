import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Styles
 */
const Wrapper = styled.div`
  text-align: center;
  border-bottom: 1px solid #CBCBCB;
  text-align: left;
  line-height: 32px;
  & .item {
    font-weight: 600;
    width: 176px;
  }
  & .address {
    margin-bottom: 2px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    line-height: 26px;
    & .item {
      width: 80px;
    }
    & .address {
      margin-bottom: 8px;
    }
    & .text {
      width: calc(100% - 80px);
    }
  }
`
const Content = styled.div`
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #222222;
  display: flex;
  padding: 40px 0 40px 24px;
@media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  padding: 18px 0 18px 16px;
  text-align: left;
}
`
const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #222222;
  &:hover {
    color: #DBAAC8;
  }
  & .image {
    width: 15px;
    height: 20px;
    margin-right: 4px;
  }
  & .map {
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0px;
    display: inline-block;
  }
`
const SpBr = styled.br`
  display: none;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: inline;
  }
`
/**
 * Props
 */
type Props = {
  image: any;
  link: string;
}

/**
 * Component
 */
export const CategoryWithMap: React.VFC<Props> = (props) => {
  return(
    <Wrapper>
      <Content>
        <div className="item">所在地</div>
        <div className="text">
          <div className="address">〒553-0003 <SpBr/>大阪市福島区福島1-4-4<SpBr/>セントラル70 3F</div>
            <StyledLink
              to={props.link}
              target="_blank"
              rel="noopener noreferrer">
              <img className="image" src={props.image.map_pin.childImageSharp.fluid.src}/>
              <div className="map">Google Maps</div>
            </StyledLink>
        </div>
      </Content>
    </Wrapper>
  )
}