import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Material
 */
import { Map } from "../map/common/Map"

/**
 * Element & Styles
 */
const Node = styled.div`
  text-align: center;
  margin: 96px 0 160px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 48px 0 80px;
  }
`

/**
 * Props
 */
type Props = {
  pinImg: any;
}

/**
 * Component
 */
export const GMap: React.VFC<Props> = (props) => {

  return (
    <Node>
      <Map pinImg={props.pinImg}/>
    </Node>
  )
}